import React, { Component } from 'react';
import axios from 'axios';
import { TextInput } from './TextInput';

//Bootstrap
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { CardHeader, CardBody, CardFooter } from 'reactstrap';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar } from '@fortawesome/free-solid-svg-icons'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

//React-Select
import Select from 'react-select'

//DataTable
import DataTable from 'react-data-table-component';


export class RAXAInventory extends Component {
    static displayName = RAXAInventory.name;

    constructor(props) {
        super(props);
        this.state = {
            newVehiclePlate: '',
            newVehicleBrand: {
                vB_ID: 0,
                vB_DESCR: ''
            },
            newVehicleModel: {
                vM_ID: 0,
                vM_DESCR: ''
            },
            newVehicleGearType: {
                id: 0,
                descr: ''
            },
            newVehicleFuelType: {
                id: 0,
                descr: ''
            },
            newVehicleModelYear: '',
            newVehicleOwnership: {
                isSelfOwned: null,
                label: ''
            },
            newVehicleType: '',
            newVehicleBrands: [],
            newVehicleModels: [],
            newVehicleGearTypes: [],
            newVehicleFuelTypes: [],
            newVehicleOwnerships: [
                { value: true, label: 'Özmal' },
                { value: false, label: 'Kiralık' },
            ],
            newVehicleTypes: [
                { value: 0, label: 'Kiralık Araç' },
                { value: 1, label: 'Çekici' },
            ],
            saveVehicleIconClasses: 'fa-spin d-none ml-2',
            saveVehicleButtonDisabled: false,
            saveStaffIconClasses: 'fa-spin d-none ml-2',
            saveStaffButtonDisabled: false,
            newStaffName: '',
            newStaffPhoneNumber: '',
            showBussyVehicles: true,
            vehicleTableColumns: [
                {
                    name: 'Plaka',
                    selector: 'plateNumber',
                    sortable: true,
                    wrap: false,
                },
                {
                    name: 'Marka',
                    selector: 'brand.vB_DESCR',
                    sortable: true,
                    wrap: false,
                },
                {
                    name: 'Model',
                    selector: 'model.vM_DESCR',
                    sortable: true,
                    wrap: false,
                },
                {
                    name: 'Yıl',
                    selector: 'modelYear',
                    sortable: true,
                    wrap: false,
                },
                {
                    name: 'Vites',
                    selector: 'gearType.descr',
                    sortable: true,
                    wrap: false,
                },
                {
                    name: 'Yakıt',
                    selector: 'fuelType.descr',
                    sortable: true,
                    wrap: false,
                },
                {
                    cell: row =>
                        <Button variant="danger" onClick={() => this.deleteInvetoryVehicle(row.id)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    ,
                    button: true,
                }
            ],
            vehicleInventoryList: [],
            staffTableColumns: [
                {
                    name: 'İsim',
                    selector: 'fullName',
                    sortable: true,
                    wrap: false,
                },
                {
                    name: 'Telefon',
                    selector: 'mobileNumber',
                    sortable: true,
                    wrap: false,
                },
                {
                    cell: row =>
                        <Button variant="danger" onClick={() => this.deleteInvetorySaff(row.id)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    ,
                    button: true,
                }
            ],
            staffInventoryList: []
        };
        this.handleNewVehiclePlateChange = this.handleNewVehiclePlateChange.bind(this);
        this.handleNewVehicleBrandChange = this.handleNewVehicleBrandChange.bind(this);
        this.handleNewVehicleModelChange = this.handleNewVehicleModelChange.bind(this);
        this.handleNewVehicleGearTypeChange = this.handleNewVehicleGearTypeChange.bind(this);
        this.handleNewVehicleFuelTypeChange = this.handleNewVehicleFuelTypeChange.bind(this);
        this.handleNewVehicleModelYearChange = this.handleNewVehicleModelYearChange.bind(this);
        this.SaveVehicleToInventory = this.SaveVehicleToInventory.bind(this);
        this.handleNewStaffNameChange = this.handleNewStaffNameChange.bind(this);
        this.handleNewStaffPhoneNumberChange = this.handleNewStaffPhoneNumberChange.bind(this);
        this.SaveStaffToInventory = this.SaveStaffToInventory.bind(this);
        this.handleNewVehicleOwnershipChange = this.handleNewVehicleOwnershipChange.bind(this);
        this.handleNewVehicleTypeChange = this.handleNewVehicleTypeChange.bind(this);
        this.GetVehicleInventory = this.GetVehicleInventory.bind(this);
        this.GetStaffInventory = this.GetStaffInventory.bind(this);
        this.toggleBussyVehicles = this.toggleBussyVehicles.bind(this);
    }

    handleNewVehiclePlateChange(evt) {
        this.setState({ newVehiclePlate: evt.target.value });
    }
    handleNewVehicleBrandChange(selectOption) {
        this.setState({ newVehicleModels: [] });
        this.setState({
            newVehicleBrand: {
                vB_ID: selectOption.value,
                vB_DESCR: selectOption.label
            }
        }, () => this.GetVehicleModelByBrandID());
    }
    handleNewVehicleModelChange(selectOption) {
        this.setState({
            newVehicleModel: {
                vM_ID: selectOption.value,
                vM_DESCR: selectOption.label
            }
        });
    }
    handleNewVehicleGearTypeChange(selectOption) {
        this.setState({
            newVehicleGearType: {
                id: selectOption.value,
                descr: selectOption.label
            }
        });
    }
    handleNewVehicleFuelTypeChange(selectOption) {
        this.setState({
            newVehicleFuelType: {
                id: selectOption.value,
                descr: selectOption.label
            }
        });
    }
    handleNewVehicleModelYearChange(evt) {
        this.setState({ newVehicleModelYear: evt.target.value });
    }

    handleNewVehicleOwnershipChange(selectOption) {
        this.setState({
            newVehicleOwnership: {
                isSelfOwned: selectOption.value,
                label: selectOption.label
            }
        });
    }

    handleNewVehicleTypeChange(selectOption) {
        this.setState({
            newVehicleType: {
                id: selectOption.value,
                label: selectOption.label
            }
        });
    }

    handleNewStaffNameChange(evt) {
        this.setState({ newStaffName: evt.target.value });
    }
    handleNewStaffPhoneNumberChange(evt) {
        this.setState({ newStaffPhoneNumber: evt.target.value });
    }
    toggleBussyVehicles() {
        this.setState({ showBussyVehicles: !this.state.showBussyVehicles }, () => { this.GetVehicleInventory() })
    }

    async GetVehicleBrands(apiRoot) {
        axios.get("api/parameter/getracbrands")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.newVehicleBrands.push({ value: value.vB_ID, label: value.vB_DESCR });
                }
            });
    }
    async GetVehicleModelByBrandID(apiRoot) {
        axios.get("api/parameter/getmodelsbybrandid/" + this.state.newVehicleBrand.vB_ID)
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.newVehicleModels.push({ value: value.vM_ID, label: value.vM_DESCR });
                }
            });
    }
    async GetVehicleGearTypes(apiRoot) {
        axios.get("api/parameter/getracgeartypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.newVehicleGearTypes.push({ value: value.id, label: value.descr });
                }
            });
    }
    async GetVehicleFuelTypes(apiRoot) {
        axios.get("api/parameter/getracfueltypes")
            .then(res => {
                for (const [, value] of res.data.data.entries()) {
                    this.state.newVehicleFuelTypes.push({ value: value.id, label: value.descr });
                }
            });
    }
    async SaveVehicleToInventory() {
        this.setState({ saveVehicleIconClasses: 'fa-spin ml-2' })
        this.setState({ saveVehicleButtonDisabled: true })
        var request = {
            BrandID: this.state.newVehicleBrand.vB_ID,
            ModelID: this.state.newVehicleModel.vM_ID,
            GearTypeID: this.state.newVehicleGearType.id,
            FuelTypeID: this.state.newVehicleFuelType.id,
            ModelYear: parseInt(this.state.newVehicleModelYear),
            PlateNumber: this.state.newVehiclePlate,
            IsSelfOwned: this.state.newVehicleOwnership.isSelfOwned,
            VehicleType: this.state.newVehicleType.id,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/raxa/savevehicle/", request)
            .then(res => {
                this.setState({ saveVehicleIconClasses: 'fa-spin d-none ml-2' });
                this.setState({ saveVehicleButtonDisabled: false });
                if (res.data.data === true) {
                    this.setState({
                        newVehiclePlate: '',
                        newVehicleBrand: {
                            vB_ID: 0,
                            vB_DESCR: ''
                        },
                        newVehicleModel: {
                            vM_ID: 0,
                            vM_DESCR: ''
                        },
                        newVehicleGearType: {
                            id: 0,
                            descr: ''
                        },
                        newVehicleFuelType: {
                            id: 0,
                            descr: ''
                        },
                        newVehicleModelYear: '',
                        newVehicleOwnership: {
                            isSelfOwned: null,
                            label: ''
                        },
                        newVehicleType: {
                            id: null,
                            label: ''
                        },
                    });
                    this.GetVehicleInventory();
                }
                alert(res.data.message);
            });
    }
    async SaveStaffToInventory() {
        this.setState({ saveStaffIconClasses: 'fa-spin ml-2' })
        this.setState({ saveStaffButtonDisabled: true })
        var request = {
            FullName: this.state.newStaffName,
            MobileNumber: this.state.newStaffPhoneNumber,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/raxa/savestaff/", request)
            .then(res => {
                this.setState({ saveStaffIconClasses: 'fa-spin d-none ml-2' });
                this.setState({ saveStaffButtonDisabled: false });
                if (res.data.data === true) {
                    this.setState({
                        newStaffName: '',
                        newStaffPhoneNumber: ''
                    });
                    this.GetStaffInventory();
                }
                alert(res.data.message);
            });
    }
    async GetVehicleInventory() {
        var request =
        {
            ShowBussyVehicles: this.state.showBussyVehicles,
            ShowDeleteVehicles: false,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/raxa/getvehiclelist/", request)
            .then(res => {
                if (res.data?.isSuccess)
                    this.setState({ vehicleInventoryList: res.data.data });
            });
    }
    async GetStaffInventory() {
        var request =
        {
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/raxa/getstafflist/", request)
            .then(res => {
                if (res.data?.isSuccess)
                    this.setState({ staffInventoryList: res.data.data });
            });
    }
    async deleteInvetoryVehicle(id) {
        var request =
        {
            ID: id,
            IsActive: false,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/raxa/updateinventoryvehicle/", request)
            .then(res => {
                if (res.data.data === true) {
                    alert("Araç Silinmiştir.");
                    this.GetVehicleInventory();
                }
            });
    }
    async deleteInvetorySaff(id) {
        var request =
        {
            ID: id,
            IsActive: false,
            UserID: parseInt(window.sessionStorage.getItem("userID")),
            UserAccessToken: window.sessionStorage.getItem("userToken")
        }
        axios.post("api/raxa/updateinventorystaff/", request)
            .then(res => {
                if (res.data.data === true) {
                    alert("Personel Silinmiştir.");
                    this.GetStaffInventory();
                }
            });
    }

    componentDidMount() {
        this.GetVehicleBrands();
        this.GetVehicleGearTypes();
        this.GetVehicleFuelTypes();
        this.GetVehicleInventory();
        this.GetStaffInventory();
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="vehicleInventory" id="uncontrolled-tab-example" className='mb-1'>
                    <Tab eventKey="vehicleInventory" title="Araç Envanteri">
                        <Card>
                            <CardHeader>
                                <h6>Yeni Araç Tanımlama</h6>
                            </CardHeader>
                            <CardBody>
                                <Form.Group className="mb-0">
                                    <Row className="mb-1">
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Araç Plakası</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <TextInput
                                                        faicon={faCar}
                                                        placeholder="Plaka"
                                                        value={this.state.newVehiclePlate}
                                                        handleChange={this.handleNewVehiclePlateChange} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Araç Markası</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        options={this.state.newVehicleBrands}
                                                        value={this.state.newVehicleBrand.vB_ID === 0 ? null : { value: this.state.newVehicleBrand.vB_ID, label: this.state.newVehicleBrand.vB_DESCR }}
                                                        onChange={this.handleNewVehicleBrandChange}
                                                        theme={theme => ({ ...theme, borderRadius: 0, })}
                                                        placeholder="Araç Markası Seçiniz"
                                                        closeMenuOnSelect={true}
                                                        className="form-control p-0 border-0 basic-multi-select"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Araç Modeli</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        options={this.state.newVehicleModels}
                                                        value={this.state.newVehicleModel.vM_ID === 0 ? null : { value: this.state.newVehicleModel.vM_ID, label: this.state.newVehicleModel.vM_DESCR }}
                                                        onChange={this.handleNewVehicleModelChange}
                                                        theme={theme => ({ ...theme, borderRadius: 0, })}
                                                        placeholder="Araç Modeli Seçiniz"
                                                        closeMenuOnSelect={true}
                                                        className="form-control p-0 border-0 basic-multi-select"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Araç Vites Tipi</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        options={this.state.newVehicleGearTypes}
                                                        value={this.state.newVehicleGearType.id === 0 ? null : { value: this.state.newVehicleGearType.id, label: this.state.newVehicleGearType.descr }}
                                                        onChange={this.handleNewVehicleGearTypeChange}
                                                        theme={theme => ({ ...theme, borderRadius: 0, })}
                                                        placeholder="Vites Tipi Seçiniz"
                                                        closeMenuOnSelect={true}
                                                        className="form-control p-0 border-0 basic-multi-select"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Araç Yakıt Tipi</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        options={this.state.newVehicleFuelTypes}
                                                        value={this.state.newVehicleFuelType.id === 0 ? null : { value: this.state.newVehicleFuelType.id, label: this.state.newVehicleFuelType.descr }}
                                                        onChange={this.handleNewVehicleFuelTypeChange}
                                                        theme={theme => ({ ...theme, borderRadius: 0, })}
                                                        placeholder="Vites Tipi Seçiniz"
                                                        closeMenuOnSelect={true}
                                                        className="form-control p-0 border-0 basic-multi-select"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Model Yılı</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <TextInput
                                                        faicon={faCalendar}
                                                        placeholder="Model Yılı"
                                                        value={this.state.newVehicleModelYear}
                                                        handleChange={this.handleNewVehicleModelYearChange} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Mülkiye Tipi</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        options={this.state.newVehicleOwnerships}
                                                        value={this.state.newVehicleOwnership.isSelfOwned == null ? null : { value: this.state.newVehicleOwnership.isSelfOwned, label: this.state.newVehicleOwnership.label }}
                                                        onChange={this.handleNewVehicleOwnershipChange}
                                                        theme={theme => ({ ...theme, borderRadius: 0, })}
                                                        placeholder="Mülkiyet Tipi Seçiniz"
                                                        closeMenuOnSelect={true}
                                                        className="form-control p-0 border-0 basic-multi-select"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Araç Tipi</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        options={this.state.newVehicleTypes}
                                                        value={this.state.newVehicleType.id === null ? null : { value: this.state.newVehicleType.id, label: this.state.newVehicleType.label }}
                                                        onChange={this.handleNewVehicleTypeChange}
                                                        theme={theme => ({ ...theme, borderRadius: 0, })}
                                                        placeholder="Araç Tipi Seçiniz"
                                                        closeMenuOnSelect={true}
                                                        className="form-control p-0 border-0 basic-multi-select"
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col>
                                        <Button variant="primary" className="float-right" onClick={this.SaveVehicleToInventory} disabled={this.state.saveVehicleButtonDisabled} >
                                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                                            <span>Envantere Ekle</span>
                                            <FontAwesomeIcon className={this.state.saveVehicleIconClasses} icon={faCircleNotch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                        <hr />
                        <Card>
                            <CardHeader>
                                <h6>Araçlarım</h6>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={this.state.vehicleTableColumns}
                                    data={this.state.vehicleInventoryList}
                                    defaultSortField="title"
                                    subHeader={true}
                                    noHeader={true}
                                    subHeaderComponent={(
                                        <div style={{ alignItems: 'center' }}>
                                            <Form.Check
                                                type="switch"
                                                id="showOnlyAvailable"
                                                label="Meşgul Araçları Göster"
                                                checked={this.state.showBussyVehicles}
                                                onChange={this.toggleBussyVehicles}
                                            />
                                        </div>
                                    )}
                                    highlightOnHover={true}
                                    striped={true}
                                />
                            </CardBody>
                        </Card>
                    </Tab>
                    <Tab eventKey="StaffInventory" title="Personel Envanteri">
                        <Card>
                            <CardHeader>
                                <h6>Yeni Personel Tanımlama</h6>
                            </CardHeader>
                            <CardBody>
                                <Form.Group className="mb-0">
                                    <Row className="mb-1">
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Personel Adı-Soyadı</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <TextInput
                                                        faicon={faUser}
                                                        placeholder="Adı Soyadı"
                                                        value={this.state.newStaffName}
                                                        handleChange={this.handleNewStaffNameChange} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={4}>
                                            <Row><Col><label>Personel Telefon Numarası</label></Col></Row>
                                            <Row>
                                                <Col>
                                                    <TextInput
                                                        faicon={faPhone}
                                                        placeholder="05XXXXXXXXX"
                                                        value={this.state.newStaffPhoneNumber}
                                                        handleChange={this.handleNewStaffPhoneNumberChange} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    <Col>
                                        <Button variant="primary" className="float-right" onClick={this.SaveStaffToInventory} disabled={this.state.saveStaffButtonDisabled} >
                                            <FontAwesomeIcon icon={faSave} className="mr-2" />
                                            <span>Envantere Ekle</span>
                                            <FontAwesomeIcon className={this.state.saveStaffIconClasses} icon={faCircleNotch} />
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                        <hr />
                        <Card>
                            <CardHeader>
                                <h6>Personellerim</h6>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    columns={this.state.staffTableColumns}
                                    data={this.state.staffInventoryList}
                                    defaultSortField="title"
                                    noHeader={true}
                                    subHeader={false}
                                    highlightOnHover={true}
                                    striped={true}
                                />
                            </CardBody>
                        </Card>
                    </Tab>
                </Tabs>
            </div>
        );
    }
}
